import formModel from "./formModel";
const {
  formField: {
    firstName,
    lastName,
    line1,
    line2,
    line3,
    locality,
    region,
    postalcode,
    hasBusinessEntity,
    ssnToken,
    ein,
  },
} = formModel;

export default {
  [firstName.name]: "Waylen",
  [lastName.name]: "Smithers",
  [line1.name]: "123 Franklin St",
  [line2.name]: "",
  [line3.name]: "",
  [locality.name]: "Brooklyn",
  [region.name]: "NY",
  [postalcode.name]: "11238",
  [hasBusinessEntity.name]: false,
  [ssnToken.name]: "123-456789",
  [ein.name]: "12-3456789",
};
