import React, { useState, useRef } from "react";
import { gql, useMutation } from "@apollo/client";

import { useSearchParams } from "react-router-dom";
import StepWizard from "react-step-wizard";
import { Formik, Form } from "formik";

import { ApplicationComplete } from "../ApplicationComplete/index";

import { VerifyForm } from "../../forms/Verify/VerifyForm";
import { ApplicationForm } from "../../forms/Application/ApplicationForm";
import { ReviewQuoteForm } from "../../forms/ReviewQuote/ReviewQuoteForm";
import { PaymentForm } from "../../forms/Payment/PaymentForm";
import { FinalReview } from "../../forms/FinalReview/FinalReview";

import { ManualReview } from "../../forms/Eligibility/ManualReview";

import validationSchema from "../../forms/validationSchema";
import formModel from "../../forms/formModel";
import formInitialValues from "../../forms/formInitialValues";

import { Headline as VerifyHeadline } from "../../forms/Verify/VerifyForm";
import { Headline as ApplicationHeadline } from "../../forms/Application/ApplicationForm";
import { Headline as ReviewQuoteHeadline } from "../../forms/ReviewQuote/ReviewQuoteForm";
import { Headline as PaymentHeadline } from "../../forms/Payment/PaymentHeadline";
import { Headline as FinalHeadline } from "../../forms/FinalReview/FinalReview";

import { RightCol, Col, Container } from "../../styled";
import { IASessionSteps, gqlErrors } from "../../constants";

import "./wizard.css";

const { formField } = formModel;

function _renderHeadline(step, organizationName, entityName) {
  switch (step) {
    case 1:
      return (
        <VerifyHeadline
          organizationName={organizationName}
          entityName={entityName}
        />
      );
    case 2:
      return <ApplicationHeadline />;
    case 3:
      return <ReviewQuoteHeadline />;
    case 4:
      return <PaymentHeadline organizationName={organizationName} />;
    case 5:
      return <FinalHeadline />;
    default:
      return <div>Not Found</div>;
  }
}

const Wizard = (props) => {
  const { data, isSandbox, iaSession } = props;

  const currentStep =
    IASessionSteps[data.insuranceApplicationSession?.currentStep]["order"];

  const currentValidationSchema = validationSchema[0];
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(currentStep);
  const [paymentError, setPaymentError] = useState(false);
  const [eligible, setEligible] = useState(
    data.insuranceApplicationSession?.isEligible
  );
  const [state, updateState] = useState({
    form: {},
    transitions: {
      enterRight: "animated enterRight",
      enterLeft: "animated enterLeft",
      exitRight: "animated exitRight",
      exitLeft: "animated exitLeft",
      intro: "animated intro",
    },
  });

  const SAVE_AND_STEP_BACK = gql`
    mutation SaveAndStepBack($currentStep: String!, $iaSession: String!) {
      saveAndStepBack(currentStep: $currentStep, iaSession: $iaSession) {
        ok
        activeStep
      }
    }
  `;

  const SAVE_AND_STEP_FORWARD = gql`
    mutation SaveAndStepForward(
      $currentStep: String!
      $iaSession: String!
      $formValues: ContractorInput!
    ) {
      saveAndStepForward(
        currentStep: $currentStep
        iaSession: $iaSession
        formValues: $formValues
      ) {
        ok
        activeStep
        embeddedSigningUrl
        esignDocumentHash
        message
      }
    }
  `;

  const ref = useRef(null);

  // const updateForm = (key, value) => {
  //   const { form } = state;

  //   form[key] = value;
  //   updateState({
  //     ...state,
  //     form,
  //   });
  // };

  async function _submitForm(values, actions) {
    console.log("submit form", values, actions);
    // await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    // actions.setSubmitting(false);

    // setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    console.log("handle submit", values, actions);
    // if (isLastStep) {
    //   _submitForm(values, actions);
    // } else {
    //   setActiveStep(activeStep + 1);
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    // }
  }

  function _handleBack() {
    console.log("handle back");
    // setActiveStep(activeStep - 1);
  }

  const [saveAndStepBack, { loading: stepBackLoading }] = useMutation(
    SAVE_AND_STEP_BACK,
    {
      onCompleted: (resp) => {
        if (resp?.saveAndStepBack?.ok) {
          state.stepWizard.goToNamedStep(resp?.saveAndStepBack?.activeStep);
          setActiveStep(state.stepWizard.currentStep);
        }
      },
    }
  );

  const [esignEmbedUrl, setEsignEmbedUrl] = useState(
    data.insuranceApplicationSession?.embeddedSigningUrl
  );
  const [esignDocumentHash, setEsignDocumentHash] = useState(
    data.insuranceApplicationSession?.esignDocumentHash
  );

  const [
    saveAndStepForward,
    { loading: stepForwardLoading, reset: stepForwardReset },
  ] = useMutation(SAVE_AND_STEP_FORWARD, {
    onCompleted: (resp) => {
      if (resp?.saveAndStepForward?.ok) {
        state.stepWizard.goToNamedStep(resp?.saveAndStepForward?.activeStep);

        if (resp?.saveAndStepForward?.activeStep === "APPLICATION_QUESTIONS") {
          setEsignEmbedUrl(resp?.saveAndStepForward.embeddedSigningUrl);
          setEsignDocumentHash(resp?.saveAndStepForward.esignDocumentHash);
        }

        setActiveStep(state.stepWizard.currentStep);
      } else {
        // it shouldn't hit this, but just in case
        if (["INVALID_STATE"].includes(resp?.saveAndStepForward?.message)) {
          alert(resp?.saveAndStepForward?.message);
        } else {
          setEligible(false);
        }
      }
    },
    onError: (error) => {
      stepForwardReset();
      error.graphQLErrors[0].extensions?.status ==
        gqlErrors.PAYMENT_NOT_AUTHORIZED &&
        setPaymentError(error.graphQLErrors[0]?.message);
    },
    refetchQueries: ["InsuranceApplicationSession"],
  });

  function filterObject(obj, callback) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, val]) => callback(val, key))
    );
  }

  function getCurrentStep() {
    const step = filterObject(IASessionSteps, (val, key) => {
      return val["order"] === state.stepWizard.currentStep;
    });

    return Object.keys(step)[0];
  }

  function stepBack() {
    const namedStep = getCurrentStep();

    saveAndStepBack({
      variables: {
        currentStep: namedStep,
        iaSession: iaSession,
      },
    });
  }

  function stepForward(setErrors, setTouched) {
    const namedStep = getCurrentStep();
    const fieldErrors = {};
    const fieldTouched = {};

    currentValidationSchema
      .validate(ref.current.values, { abortEarly: false })
      .then((validData) => {
        saveAndStepForward({
          variables: {
            currentStep: namedStep,
            iaSession: iaSession,
            formValues: ref.current.values,
          },
        });
      })
      .catch((validationErrors) => {
        validationErrors.inner.forEach((item) => {
          fieldErrors[item.path] = item.message;
          fieldTouched[item.path] = true;
        });
        setErrors(fieldErrors);
        setTouched(fieldErrors);
      });
  }

  function rightColMinPadding(activeStep) {
    /*
    Note: Final review content is extra tall 
    so minimizing padding for that step. Reuse
    anywhere you need to minimize padding to fit
    extra tall content.
    */
    const step = filterObject(IASessionSteps, (val, key) => {
      return val["order"] === activeStep;
    });
    return !!step?.FINAL_REVIEW?.name;
  }

  const setInstance = (stepWizard) => {
    updateState({
      ...state,
      stepWizard,
    });
  };

  function base64Decode(id) {
    let res = atob(id);
    return Number(res.toString("ascii").split(":")[1]);
  }

  const unredacted = JSON.parse(
    data.insuranceApplicationSession?.contractor?.unredactedDict
  );

  formInitialValues.firstName = unredacted.first_name;
  formInitialValues.middleName = unredacted.middle_name;
  formInitialValues.lastName = unredacted.last_name;
  formInitialValues.line1 = unredacted.address.line1;
  formInitialValues.line2 = unredacted.address.line2;
  formInitialValues.line3 = unredacted.address.line3;
  formInitialValues.locality = unredacted.address.locality;
  formInitialValues.region = unredacted.address.region;
  formInitialValues.postalcode = unredacted.address.postalcode;
  formInitialValues.hasBusinessEntity = unredacted.has_business_entity;
  formInitialValues.ssnToken = unredacted.tax_identification.ssn_token || "";
  formInitialValues.ein = unredacted.tax_identification.ein || "";

  const paymentClientToken =
    data.insuranceApplicationSession?.contractor?.paymentClientToken;
  const paymentMethod =
    data.insuranceApplicationSession?.contractor?.paymentMethod;

  const isEsignatureComplete =
    data.insuranceApplicationSession?.isEsignatureComplete;
  const policies = data.insuranceApplicationSession?.policies;
  const successUrl = data.insuranceApplicationSession?.successUrl;
  const cancelUrl = data.insuranceApplicationSession?.cancelUrl;
  const organizationName =
    data.insuranceApplicationSession?.quotes?.edges[0]?.node?.tenant
      ?.organization?.name;
  const entityName =
    data.insuranceApplicationSession?.quotes?.edges[0]?.node?.job?.entity?.name;

  const orgId =
    data.insuranceApplicationSession?.quotes?.edges[0]?.node?.tenant
      ?.organization.publicId;

  if (policies.edges.length > 0) {
    return (
      <ApplicationComplete
        orgId={orgId}
        successUrl={successUrl}
        cancelUrl={cancelUrl}
        policies={policies}
        client={props.client}
      />
    );
  }

  if (eligible === false) {
    return <ManualReview data={data} />;
  }

  return (
    <div className="App">
      <header className="App-header">
        <article className="cf overflow-hidden-l">
          <Col>{_renderHeadline(activeStep, organizationName, entityName)}</Col>
          <RightCol>
            <Container minpadding={rightColMinPadding(activeStep)}>
              <input type="hidden" name="activeStep" value={activeStep} />
              <Formik
                innerRef={ref}
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={_handleSubmit}
              >
                {(props) => {
                  const {
                    setFieldValue,
                    values,
                    errors,
                    setErrors,
                    touched,
                    setTouched,
                  } = props;

                  return (
                    <StepWizard
                      initialStep={currentStep}
                      isHashEnabled={false}
                      transitions={state.transitions}
                      instance={setInstance}
                    >
                      <VerifyForm
                        touched={touched}
                        errors={errors}
                        values={values}
                        stepBack={stepBack}
                        stepForward={stepForward}
                        stepForwardLoading={stepForwardLoading}
                        stepName={IASessionSteps.VERIFY_INFO.name}
                        formField={formField}
                        businessEntityChoice={
                          data.insuranceApplicationSession?.contractor
                            ?.hasBusinessEntity
                        }
                        setFieldValue={setFieldValue}
                        setErrors={setErrors}
                        setTouched={setTouched}
                      />
                      <ApplicationForm
                        stepBack={stepBack}
                        stepForwardLoading={stepForwardLoading}
                        stepBackLoading={stepBackLoading}
                        stepForward={stepForward}
                        stepName={IASessionSteps.APPLICATION_QUESTIONS.name}
                        formField={formField}
                      />
                      <ReviewQuoteForm
                        stepBack={stepBack}
                        stepForward={stepForward}
                        stepForwardLoading={stepForwardLoading}
                        stepName={IASessionSteps.ESIGNATURE_DOCUMENT.name}
                        formField={formField}
                        esignEmbedUrl={esignEmbedUrl}
                        documentHash={esignDocumentHash}
                        isEsignatureComplete={isEsignatureComplete}
                      />
                      <PaymentForm
                        isSandbox={isSandbox}
                        paymentError={paymentError}
                        setPaymentError={setPaymentError}
                        stepBack={stepBack}
                        stepForward={stepForward}
                        stepForwardLoading={stepForwardLoading}
                        stepName={IASessionSteps.ADD_CARD_DETAILS.name}
                        formField={formField}
                        paymentClientToken={paymentClientToken}
                        paymentMethod={paymentMethod}
                      />
                      <FinalReview
                        stepName={IASessionSteps.FINAL_REVIEW.name}
                        data={data}
                      />
                    </StepWizard>
                  );
                }}
              </Formik>
            </Container>
          </RightCol>
        </article>
      </header>
    </div>
  );
};

export default Wizard;
