import styled from "styled-components";

const Container = styled.div.attrs({
  className: "f6 gray dtc v-btm",
})``;

function Subheadline(props) {
  return <Container>{props.children}</Container>;
}

export default Subheadline;
