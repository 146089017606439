import React from "react";

import { IneligibilityWrapper } from "./Ineligible";

export function ManualReview(props) {
  return (
    <IneligibilityWrapper>
      <h1 className="f1 lh-title near-black">Manual Review</h1>
      <div className="center tc mw7">
        <p className="f4 lh-copy near-black">
          Based on the information you've provided, we need to manually review
          your application. We'll review and follow-up with you within 24 hours.
          If you have any questions between now and then, feel free to reach out
          to us at <b>650-753-1099</b>.
        </p>
      </div>
    </IneligibilityWrapper>
  );
}
