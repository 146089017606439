import { Field } from "formik";

const RadioButton = ({ name, value, onClick }) => (
  <div className="mv2">
    <label className="f6 fw5 mb2 mt3 ml2" htmlFor={name}>
      <Field type="radio" onClick={onClick} name={name} value={value} /> {"  "}
      {value}
    </label>
  </div>
);

export default RadioButton;
