import React, { useState, Fragment } from "react";

import { Field, ErrorMessage, getIn } from "formik";

import styled from "styled-components";

import Subheadline from "../../components/Subheadline";
import AnimatedButton from "../../components/AnimatedButton";
import SSNInput from "../../components/SSNInput";

import { Title } from "../../styled";
import classNames from "classnames";

const Flex = styled.div.attrs({
  className: "flex-l",
})``;

const FieldGroupSplit = styled.div.attrs({
  className: "db w-100 w-50-l",
})``;

const FieldGroupSplitGap = styled.div.attrs({
  className: "db w-100 w-50-l pl3-l",
})``;

const Label = styled.label.attrs({
  className: "f6 fw5 db mb2 mt3",
})``;

const Container = styled.div.attrs({
  className: "pa3 pa4-m pv5-l ph6-l",
})`
  @media screen and (min-width: 70em) {
    background-image: url("/img/verify.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: 370px 65px;
  }
`;

const Body = styled.p.attrs({
  className: "lh-copy measure-wide",
})``;

export function Headline(props) {
  return (
    <Container>
      <Title>
        Verify your <br /> information
      </Title>
      <Subheadline>
        <Body>
          Indepedent contractors that do work for <b>{props.entityName}</b> are
          required to have workers compensation insurance.
        </Body>
        <Body>
          <b>{props.organizationName}</b> is partnering with 1099Policy to make
          it easy for contractors on their platform to buy workers compensation
          insurance on a pay-as-you go basis.
        </Body>
      </Subheadline>
    </Container>
  );
}

export function VerifyForm(props) {
  const {
    touched,
    setTouched,
    errors,
    setErrors,
    values,
    stepForward,
    stepForwardLoading,
    setFieldValue,
    businessEntityChoice,
    formField: {
      firstName,
      middleName,
      lastName,
      line1,
      line2,
      line3,
      locality,
      region,
      postalcode,
      hasBusinessEntity,
      ssnToken,
      ein,
    },
  } = props;

  const [businessEntity, setBusinessEntity] = useState(
    businessEntityChoice,
    false
  );

  const setRegion = (e) => {
    const state = e.target.value.toUpperCase();

    if (state.length > 2) {
      return false;
    }

    setFieldValue("region", state);
  };

  function setBusinessEntityChoice(e) {
    const hasBusinessEntity = e?.target?.value === "Yes";
    setFieldValue("hasBusinessEntity", hasBusinessEntity);
    setBusinessEntity(hasBusinessEntity);
  }

  function setTaxIdSSN(taxId) {
    setFieldValue("ssnToken", taxId);
  }

  const isFieldWithError = (fieldName) => {
    return getIn(errors, fieldName) && getIn(touched, fieldName);
  };

  return (
    <Fragment>
      <Flex>
        <FieldGroupSplit>
          <Label htmlFor="firstName">First Name</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              getIn(errors, firstName.name) ? "b--red" : "b--black-20"
            )}
            name={firstName.name}
            placeholder="Lucy"
          />
          <ErrorMessage name="firstName" component="small" className="f7 red" />
        </FieldGroupSplit>

        <FieldGroupSplitGap>
          <Label htmlFor="middleName">Middle Name</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              isFieldWithError(middleName.name) ? "b--red" : "b--black-20"
            )}
            name={middleName.name}
            placeholder=""
          />
          <ErrorMessage
            name="middleName"
            component="small"
            className="f7 red"
          />
        </FieldGroupSplitGap>

        <FieldGroupSplitGap>
          <Label htmlFor="lastName">Last Name</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              isFieldWithError(lastName.name) ? "b--red" : "b--black-20"
            )}
            name={lastName.name}
            placeholder="Schultz"
          />
          <ErrorMessage name="lastName" component="small" className="f7 red" />
        </FieldGroupSplitGap>
      </Flex>

      <div className="db mt3 mb1 w-100">
        <Fragment>
          <div className="relative dib w-100 pv3">
            <label className="ml0">Do you operate as a business entity?</label>
            <div className="fr-l mt3 mt0-l">
              <Field type="hidden" name={hasBusinessEntity.name} />
              <label className="dib">
                <input
                  type="radio"
                  value="Yes"
                  checked={businessEntity}
                  name="businessEntityToggle"
                  onChange={setBusinessEntityChoice}
                />{" "}
                Yes
              </label>
              <label className="pl3 dib">
                <input
                  type="radio"
                  value="No"
                  checked={!businessEntity}
                  name="businessEntityToggle"
                  onChange={setBusinessEntityChoice}
                />{" "}
                No
              </label>
            </div>
          </div>
        </Fragment>
      </div>

      <Flex>
        {!businessEntity && (
          <FieldGroupSplit>
            <Label htmlFor="ssnToken">Tax ID (SSN)</Label>
            <SSNInput
              setTaxIdSSN={setTaxIdSSN}
              ssnToken={ssnToken}
              values={values}
              errors={errors}
              touched={touched}
            />
            <ErrorMessage
              name="ssnToken"
              component="small"
              className="f7 red"
            />
          </FieldGroupSplit>
        )}
        {!!businessEntity && (
          <FieldGroupSplit>
            <Label htmlFor="ein">Tax ID (EIN)</Label>
            <Field
              type="text"
              className={classNames(
                "input-reset pa2 mb2 db w-100 br2 ba",
                isFieldWithError(ein.name) ? "b--red" : "b--black-20"
              )}
              name={ein.name}
              placeholder="12-3456789"
            />
            <ErrorMessage name="ein" component="small" className="f7 red" />
          </FieldGroupSplit>
        )}
      </Flex>

      <Label htmlFor={line1.name}>Line 1</Label>
      <Field
        type="text"
        className={classNames(
          "input-reset pa2 mb2 db w-100 br2 ba",
          isFieldWithError(line1.name) ? "b--red" : "b--black-20"
        )}
        name={line1.name}
        placeholder="45 Bartlett"
      />
      <ErrorMessage name={line1.name} component="small" className="f7 red" />

      <Label htmlFor={line2.name}>Line 2</Label>
      <Field
        type="text"
        className={classNames(
          "input-reset pa2 mb2 db w-100 br2 ba",
          isFieldWithError(line2.name) ? "b--red" : "b--black-20"
        )}
        name={line2.name}
        placeholder="Unit 707"
      />
      <ErrorMessage name={line2.name} component="small" className="f7 red" />

      <Label htmlFor={line3.name}>Line 3</Label>
      <Field
        type="text"
        className={classNames(
          "input-reset pa2 mb2 db w-100 br2 ba",
          isFieldWithError(line3.name) ? "b--red" : "b--black-20"
        )}
        name={line3.name}
        placeholder="..."
      />
      <ErrorMessage name={line3.name} component="small" className="f7 red" />

      <Flex>
        <div className="db w-60-l w-100">
          <Label htmlFor={locality.name}>City</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              isFieldWithError(locality.name) ? "b--red" : "b--black-20"
            )}
            name={locality.name}
            placeholder="San Francisco"
          />
          <ErrorMessage
            name={locality.name}
            component="small"
            className="f7 red"
          />
        </div>

        <div className="db ph3-l w-20-l w-100">
          <Label htmlFor={region.name}>State</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              isFieldWithError(region.name) ? "b--red" : "b--black-20"
            )}
            name={region.name}
            onChange={setRegion}
            placeholder="CA"
          />
          <ErrorMessage
            name={region.name}
            component="small"
            className="f7 red"
          />
        </div>

        <div className="db w-25-l w-100">
          <Label htmlFor={postalcode.name}>Zip</Label>
          <Field
            type="text"
            className={classNames(
              "input-reset pa2 mb2 db w-100 br2 ba",
              isFieldWithError(postalcode.name) ? "b--red" : "b--black-20"
            )}
            name={postalcode.name}
            placeholder="94110"
          />
          <ErrorMessage
            name={postalcode.name}
            component="small"
            className="f7 red"
          />
        </div>
      </Flex>

      <div className="mt3 fr">
        <AnimatedButton
          isSubmitting={stepForwardLoading}
          onClick={() => stepForward(setErrors, setTouched)}
          primary
        >
          Continue
        </AnimatedButton>
      </div>
    </Fragment>
  );
}
