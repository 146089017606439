function Icon(brand) {
  switch (brand) {
    case "visa":
      return (
        <img
          style={{ height: 20, verticalAlign: "bottom" }}
          src="/img/cards/visa.svg"
          alt="credit card brand"
        />
      );
    case "amex":
      return (
        <img
          style={{ height: 20, verticalAlign: "bottom" }}
          src="/img/cards/amex.svg"
          alt="credit card brand"
        />
      );
    case "diners":
      return (
        <img
          style={{ height: 20, verticalAlign: "bottom" }}
          src="/img/cards/diners.svg"
          alt="credit card brand"
        />
      );
    case "jcb":
      return (
        <img
          style={{ height: 20, verticalAlign: "bottom" }}
          src="/img/cards/jcb.svg"
          alt="credit card brand"
        />
      );
    case "mastercard":
      return (
        <img
          style={{ height: 20, verticalAlign: "bottom" }}
          src="/img/cards/mastercard.svg"
          alt="credit card brand"
        />
      );
    default:
      return "";
  }
}

export default Icon;
