import React, { useState, Fragment } from "react";
import { Field, getIn } from "formik";
import classNames from "classnames";

const SSNInput = (props) => {
  const ssnToken = props?.values?.ssnToken || "";
  const [rawSSN, setRawSSN] = useState(ssnToken);
  const [ssn, setSSN] = useState("");
  const errors = props.errors || {};
  const touched = props.touched;

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (value.replace(/-/g, "").length < rawSSN.length) {
      const last = rawSSN.length - 1;
      const newSSN = rawSSN.slice(0, last);
      setRawSSN(newSSN);
      props.setTaxIdSSN(newSSN);
      return;
    }

    let numValue = value.replace(/\D/g, "");

    let newSSN = "";
    if (rawSSN.length > 5) {
      newSSN = rawSSN.slice(0, 5) + numValue;
    } else {
      newSSN = rawSSN + numValue;
    }

    if (newSSN.length > 9) {
      return;
    }
    setSSN(newSSN);
    setRawSSN(newSSN);
    props.setTaxIdSSN(newSSN);
  };

  const format = (v) => {
    v = v.slice(0, 11).replace(/-/g, "");
    if (v.length <= 3) {
      return v;
    }
    if (v.length > 3 && v.length <= 5) {
      return `${v.slice(0, 3)}-${v.slice(3)}`;
    }
    if (v.length > 5) {
      return `${v.slice(0, 3)}-${v.slice(3, 5)}-${v.slice(5)}`;
    }
  };

  const mask = (v) => {
    const masked = v.slice(0, 7).replace(/[0-9]/g, "*");
    const final = masked + v.slice(7);
    return final;
  };

  return (
    <>
      <Field
        name={props.ssnToken.name}
        type="text"
        className={classNames(
          "input-reset pa2 mb2 db w-100 br2 ba",
          getIn(errors, props.ssnToken.name) && getIn(touched, props.ssnToken.name) ? "b--red" : "b--black-20"
        )}
        id="ssn"
        value={mask(format(rawSSN))}
        onChange={handleChange}
        autoComplete="off"
        placeholder="123-45-6789"
      />
    </>
  );
};

export default SSNInput;
