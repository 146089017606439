import * as Yup from "yup";
import verifyFormModel from "./formModel";
import states from "../constants/states";

const {
  formField: {
    firstName,
    lastName,
    line1,
    locality,
    region,
    postalcode,
    ssnToken,
    ein,
    eligibilityQuestion,
  },
} = verifyFormModel;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [line1.name]: Yup.string().required(`${line1.requiredErrorMsg}`),
    [locality.name]: Yup.string().required(`${locality.requiredErrorMsg}`),
    [region.name]: Yup.string()
      .required(`${region.requiredErrorMsg}`)
      .test(region.name, region.invalidErrorMsg, (value) => {
        if (value) {
          return Object.keys(states).includes(value.toUpperCase());
        }
      }),
    [postalcode.name]: Yup.string()
      .required(`${postalcode.requiredErrorMsg}`)
      .test(
        "len",
        `${postalcode.invalidErrorMsg}`,
        (val) => val && val.length === 5
      ),
    // NOTE: ---- KEEPING OPTIONAL FOR NOW 11/21/23 -----
    //
    // [ssnToken.name]: Yup.string().when('hasBusinessEntity', {
    //   is: false,
    //   then: Yup.string().required(`${ssnToken.requiredErrorMsg}`),
    //   otherwise: Yup.string(),
    // }),
    // [ein.name]: Yup.string().when('hasBusinessEntity', {
    //   is: true,
    //   then: Yup.string().required(`${ein.requiredErrorMsg}`),
    //   otherwise: Yup.string(),
    // }),

    // second page rules
    [eligibilityQuestion.name]: Yup.string().when("activeStep", {
      is: 2,
      then: Yup.string().required(`${eligibilityQuestion.requiredErrorMsg}`),
      otherwise: Yup.string(),
    }),
  }),
];
