import React from "react";
import { paymentTypes } from "../../constants";

const CheckoutPaymentForm = React.lazy(() => import("./CheckoutPaymentForm"));
const StripePaymentForm = React.lazy(() => import("./StripePaymentForm"));

export const PaymentForm = (props) => {
  let provider = process.env.REACT_APP_PAYMENT_PROVIDER || "checkout";

  return (
    <>
      <React.Suspense fallback={<></>}>
        {provider === paymentTypes.CHECKOUT && (
          <CheckoutPaymentForm {...props} />
        )}
        {provider === paymentTypes.STRIPE && <StripePaymentForm {...props} />}
      </React.Suspense>
    </>
  );
};

export default PaymentForm;
