import { Title } from "../../styled";
import Subheadline from "../../components/Subheadline";
import styled from "styled-components";

const Container = styled.div.attrs({
  className: "pa3 pa4-m pv5-l ph6-l",
})`
  @media screen and (min-width: 70em) {
    background-image: url("/img/payment.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: 360px 80px;
  }
`;

const Body = styled.p.attrs({
  className: "lh-copy measure-wide",
})``;

export function Headline(props) {
  return (
    <Container>
      <Title>
        Add a payment <br /> method
      </Title>
      <Subheadline>
        <Body>
          For your convenience, we offer different payment options (credit/debit
          card) for work assignments you take through{" "}
          <b>{props.organizationName}</b>.
        </Body>
        <Body>
          You'll have a chance to review and confirm the full premium for this
          coverage again on the final step of this application before we charge
          your card. Note that you may see a hold on your card for the premium
          due that will be voided if you decide not to complete the application.
        </Body>
      </Subheadline>
    </Container>
  );
}
