import styled from "styled-components";

export const Title = styled.h1.attrs({
  className: "f1-l lh-title mv0-m mb6-l near-black",
})``;

export const Col = styled.div.attrs({
  className: "fl",
})`
  width: 100%;
  @media screen and (min-width: 60em) {
    width: 50%;
  }
`;

export const RightCol = styled.div.attrs({
  className: "fl w-100 w-50-l",
})`
  transform-origin: right;
  height: 80vh;
  @media screen and (min-width: 60em) {
    box-shadow: 15px 0 30px 0 rgb(0 0 0 / 18%);
    height: 100vh;
  }
`;

export const Container = styled.div.attrs({
  className: "pa3 pa4-m pv5-l pa5-l",
})`
  ${({ minpadding }) =>
    minpadding &&
    `
    padding: 1rem;
  `}
`;

export const IframeWrapperDiv = styled.div.attrs({
  className: "overflow-y-hidden flex flex-column",
})`
  height: 80vh;
  @media screen and (min-width: 60em) {
    height: 86vh;
  }
`;
