import React from "react";
import ReactLoading from "react-loading";
import classNames from "classnames";

import "./animatedButton.css";

const AnimatedButton = (props) => {
  const {
    id,
    children,
    onClick,
    isDisabled,
    primary,
    customStyle,
    inlineStyle,
    isSubmitting,
  } = props;
  if (isSubmitting) {
    return (
      <button
        disabled={true}
        className="f6 link br2 ph3 pv2 mb2 dib white bg-black-10 bn"
      >
        <ReactLoading
          type={"spin"}
          color={"#ffffff"}
          height={15}
          width={15}
          className="dib v-bottom"
        />{" "}
        &nbsp; Submitting...
      </button>
    );
  }

  return (
    <button
      id={id}
      disabled={isDisabled}
      onClick={onClick}
      style={inlineStyle}
      className={classNames(
        "f6 link br2 ph3 pv2 mb2 dib pointer animate",
        primary ? "white bn" : "black bg-white b--black ba",
        customStyle && customStyle,
        !isDisabled ? "bg-black" : "bg-black-10"
      )}
    >
      {children}
    </button>
  );
};

export default AnimatedButton;
