import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import styled from "styled-components";

import Subheadline from "../../components/Subheadline";
import AnimatedButton from "../../components/AnimatedButton";

import Icon from "../../utils/cardIcon";
import { centsToDollars } from "../../utils/currency";

import { paymentTypes } from "../../constants";

import { RightCol, Col, Title } from "../../styled";

const CENTS = 100;
const PERCENT = 100;

const Body = styled.p.attrs({
  className: "lh-copy measure-wide",
})``;

const Container = styled.div.attrs({
  className: "pa3 pa6-ns",
})`
  background-image: url("/img/clipboard.svg");
  z-index: -1;
  background-repeat: no-repeat;
  background-size: 40% 40%;
  background-position: 400px 100px;
`;

const BIND_POLICY_QUERY = gql`
  mutation BindInsurancePolicy($quoteId: String!, $iaSession: String!) {
    bindInsurancePolicy(quoteId: $quoteId, iaSession: $iaSession) {
      ok
      policy {
        id
      }
    }
  }
`;

const CheckoutPaymentStatus = () => {
  let message = "Success! Your payment method has been saved.";
  return message;
};

const StripePaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent.status) {
        case "succeeded":
          setMessage("Success! Your payment method has been saved.");
          break;

        case "processing":
          setMessage(
            "Processing payment details. We'll update you when processing is complete."
          );
          break;

        case "requires_payment_method":
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage(
            "Failed to process payment details. Please try another payment method."
          );
          break;
      }
    });
  }, [stripe]);

  return message;
};

export function Headline() {
  return (
    <Container>
      <Title>Final Review</Title>
      <Subheadline>
        <Body>
          We're ready to issue you your pay-as-you-go insurance policy. Clicking
          the "Complete Application" button will activate your insurance
          coverage which you can use to take on new job assignments. If you're
          paying via credit card we'll also charge the card you have on file.
        </Body>
      </Subheadline>
    </Container>
  );
}

function Banner(props) {
  return (
    <div className="flex items-center justify-center pa1 pa4-l navy">
      <span className="lh-title ml3-l bg-lightest-blue ph2 ph4-l pv2 br2">
        {props.children}
      </span>
    </div>
  );
}

function getCoverageType(quote) {
  if (quote?.isGlAndWc === "true") {
    return (
      <>
        General Liability & <br />
        Workers Compensation
      </>
    );
  }
  if (quote?.isWcOnly === "true") {
    return "Workers Compensation";
  }
  if (quote?.isGlOnly === "true") {
    return "General Liability";
  }
}

function ConfirmationTable(props) {
  const { iaSession } = useParams();
  const [bindPolicy, { loading, error, reset }] = useMutation(
    BIND_POLICY_QUERY,
    {
      onCompleted: () => {
        console.log("application complete");
        reset();
      },
      refetchQueries: ["InsuranceApplicationSession"],
    }
  );

  const {
    paymentProvider,
    data: {
      insuranceApplicationSession: {
        contractor: { unredactedDict, paymentMethod },
        quotes: { edges },
      },
    },
  } = props;

  const unredacted = JSON.parse(unredactedDict);
  const quoteId = edges[0].node?.id;
  const effectiveDate = new Date(edges[0].node?.effectiveDate);
  const paymentMethodJSON = JSON.parse(paymentMethod);
  const includesGL =
    edges[0].node?.isGlOnly === "true" || edges[0].node?.isGlAndWc === "true";
  const wage = edges[0].node?.job?.wage;

  let paymentStatus;

  switch (paymentProvider) {
    case paymentTypes.CHECKOUT:
      paymentStatus = <CheckoutPaymentStatus />;
      break;
    case paymentTypes.STRIPE:
      paymentStatus = <StripePaymentStatus />;
      break;
    default:
      paymentStatus = <></>;
  }

  return (
    <>
      {paymentMethodJSON && <Banner>{paymentStatus}</Banner>}
      <div className="pa1 pa4-l pt0">
        <div className="overflow-auto">
          <table className="f6 w-100 w-70-ns mw8 center-l" cellSpacing="0">
            <tbody className="lh-copy">
              <tr>
                <td className="pv2 pr3 gray">Name</td>
                <td className="pv2 pl3 pl0-ns pr3">
                  {unredacted.first_name} {unredacted.last_name}
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray v-top">Address</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  <div>{unredacted.address.line1}</div>
                  {unredacted.address.line2 ? (
                    <div>{unredacted.address.line2}</div>
                  ) : null}
                  {unredacted.address.line3 ? (
                    <div>{unredacted.address.line3}</div>
                  ) : null}
                  <div>
                    {unredacted.address.locality}, {unredacted.address.region}{" "}
                    {unredacted.address.postalcode}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray">Contracting entity</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  {edges[0].node?.job?.entity?.name}
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray">Job Assignment</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  {edges[0].node?.job?.name}
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray">Coverage type</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  {getCoverageType(edges[0].node)}
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray">Effective date</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  {effectiveDate.toDateString()}
                </td>
              </tr>
              <tr>
                <td className="pv2 pr3 gray">Net rate</td>
                <td className="pv2 pl3 pl0-ns pr3-ns">
                  {centsToDollars(edges[0].node?.netRate)} per $100 earned
                </td>
              </tr>
              {wage && (
                <tr>
                  <td className="pv2 pr3 gray">
                    Premium owed (based on {centsToDollars(wage)} wage)
                    <br />
                    {includesGL && "+ taxes and fees"}
                  </td>
                  <td className="pv2 pl3 pl0-ns pr3-ns">
                    {centsToDollars(edges[0].node?.premiumAmountOwed)}
                  </td>
                </tr>
              )}
              {paymentMethodJSON ? (
                <tr>
                  <td className="pv2 pr3 gray">Payment method</td>
                  <td className="pv2 pl3 pl0-ns pr3">
                    {Icon(paymentMethodJSON.brand)} &nbsp;••••
                    {paymentMethodJSON.last4}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th colSpan="2" className="dib mt4">
                  <AnimatedButton
                    isSubmitting={loading}
                    onClick={() =>
                      bindPolicy({
                        variables: { quoteId: quoteId, iaSession: iaSession },
                      })
                    }
                    primary
                  >
                    Complete Application
                  </AnimatedButton>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

function FinalReviewWrapper(props) {
  const { paymentProvider, children } = props;

  if (paymentProvider === paymentTypes.STRIPE) {
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    const stripePromise = loadStripe(
      "pk_test_9Z12C94UZkJ8NRU3rSan6qnY00Lcaxwf17"
    );

    return <Elements stripe={stripePromise}>{children}</Elements>;
  } else {
    return <>{children}</>;
  }
}

export function FinalReview(props) {
  let provider = process.env.REACT_APP_PAYMENT_PROVIDER || "checkout";

  return (
    <FinalReviewWrapper paymentProvider={provider}>
      <ConfirmationTable {...props} paymentProvider={provider} />
    </FinalReviewWrapper>
  );
}
