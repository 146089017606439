export default {
  formId: "insuranceApplication",
  formField: {
    firstName: {
      name: "firstName",
      label: "First name*",
      requiredErrorMsg: "First name is required",
    },
    middleName: {
      name: "middleName",
      label: "Middle name*",
      requiredErrorMsg: "Middle name is required",
    },
    lastName: {
      name: "lastName",
      label: "Last name*",
      requiredErrorMsg: "Last name is required",
    },
    phone: {
      name: "phone",
      label: "Phone",
      requiredErrorMsg: "Phone is required",
    },
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Email is required",
    },
    line1: {
      name: "line1",
      label: "Address Line 1*",
      requiredErrorMsg: "Address Line 1 is required",
    },
    line2: {
      name: "line2",
      label: "Address Line 2",
    },
    line3: {
      name: "line3",
      label: "Address Line 3",
    },
    locality: {
      name: "locality",
      label: "City*",
      requiredErrorMsg: "City is required",
    },
    region: {
      name: "region",
      label: "State",
      requiredErrorMsg: "State is required",
      invalidErrorMsg: "State is not valid (e.g. CA)",
    },
    postalcode: {
      name: "postalcode",
      label: "Zipcode*",
      requiredErrorMsg: "Zipcode is required",
      invalidErrorMsg: "Zipcode is not valid (e.g. 70000)",
    },
    hasBusinessEntity: {
      name: "hasBusinessEntity",
      label: "hasBusinessEntity[hidden]",
      requiredErrorMsg: "",
      invalidErrorMsg: "",
    },
    ssnToken: {
      name: "ssnToken",
      label: "Tax Identification (SSN)*",
      requiredErrorMsg: "SSN is required",
      invalidErrorMsg: "SSN is not valid (e.g. 123-456789)",
    },
    ein: {
      name: "ein",
      label: "Tax Identification (EIN)*",
      requiredErrorMsg: "EIN is required",
      invalidErrorMsg: "EIN is not valid (e.g. 12-3456789)",
    },
    eligibilityQuestion: {
      name: "losses",
      label:
        "Have you submitted more than one workers' compensation claim in the last 3 years?",
      requiredErrorMsg: "Please choose yes or no",
      invalidErrorMsg: "Select yes or no to continue",
    },
  },
};
