import CelebrateSvg from "./CelebrateSvg";

const Success = () => (
  <article className="vh-100 dt w-100">
    <div className="dtc v-mid tc white ph3 ph4-l">
      <h1 className="f1 lh-title near-black">eSignature document signed!</h1>
      <CelebrateSvg />
      <div className="center tc mw7">
        <p className="f3 lh-copy near-black">
          We'll automatically redirect you to the "Final Review" step to review
          your coverage details and activate your coverage. If you're not
          automatically redirected, click "Continue below".
        </p>
      </div>
    </div>
  </article>
);

export default Success;
