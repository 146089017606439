import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import InsuranceApplication from "./routes/Insurance/InsuranceApplication";
import { Success as EsignSuccess } from "./components/EsignRedirect";
import { Error } from "./components/Error/index";

import "tachyons/css/tachyons.min.css";
import { environments } from "./constants";

function App() {
  const isSandbox = window.location.pathname
    .split("/")
    .includes(environments.SANDBOX);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_TEN99_URL,
    headers: {
      "Ten99Policy-Environment": isSandbox
        ? environments.SANDBOX
        : environments.PRODUCTION,
    },
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/:iaSession"
            element={<InsuranceApplication client={client} isSandbox={false} />}
          ></Route>
          <Route
            path="sandbox/:iaSession"
            element={<InsuranceApplication client={client} isSandbox={true} />}
          ></Route>
          <Route path="esign/success" element={<EsignSuccess />}></Route>
          <Route path="/" element={<Error />}></Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
