import { ReactComponent as CustomerServiceSvg } from "./customerservice.svg";

export function IneligibilityWrapper(props) {
  const { provider, children } = props;

  return (
    <article className="vh-100 dt w-100">
      <div className="dtc v-mid tc white ph3 ph4-l">
        <CustomerServiceSvg />
        {children}
      </div>
    </article>
  );
}

export function Ineligible(props) {
  return (
    <IneligibilityWrapper>
      <h1 className="f1 lh-title near-black">Contact Us</h1>
      <div className="center tc mw7">
        <p className="f4 lh-copy near-black">
          Based on the information you've provided, you're <b>ineligible</b> for
          pay-as-you-go insurance coverage offered via 1099Policy Insurance
          Agency. If you need help finding alternative coverage that meets the
          vendor requirements for the job assignment you're looking to take,
          reach out to us at <b>650-753-1099</b>. We'll do our best to help.
        </p>
      </div>
    </IneligibilityWrapper>
  );
}
