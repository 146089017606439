import ReactLoading from "react-loading";

import { ReactComponent as WinnersSvg } from "./winners.svg";

import AnimatedButton from "../../components/AnimatedButton";

import { usePusherChannel } from "../../utils/pusher";
import { pusherEvents } from "../../constants";

import { useState } from "react";
import Pusher from "pusher-js";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || "",
});

const downloadIcon = (
  <img
    style={{ height: 20, verticalAlign: "bottom" }}
    src="/img/download.svg"
    alt="download icon"
  />
);

const DownloadButton = (props) => {
  return (
    <a
      href={props.url}
      download="CertificateOfInsurance"
      target="_blank"
      className="no-underline"
    >
      <AnimatedButton customStyle={"inline-flex items-center"} primary>
        {downloadIcon} &nbsp; Download your certificate of insurance
      </AnimatedButton>
    </a>
  );
};

const LoadingButton = (props) => {
  return (
    <button
      disabled={true}
      className="f6 link br2 ph3 pv2 mb2 dib white bg-black-10 bn"
    >
      <ReactLoading
        type={"spin"}
        color={"#ffffff"}
        height={15}
        width={15}
        className="dib v-bottom"
      />{" "}
      &nbsp; Generating certificate...
    </button>
  );
};

function ApplicationComplete(props) {
  const orgId = props.orgId;
  const channelName = `policy-${orgId}`;

  const [coiPdfUrl, setCoiPdfUrl] = useState(
    props.policies.edges[0].node.pdfUrl
  );
  const successUrl = props.successUrl;

  usePusherChannel(
    pusher,
    channelName,
    pusherEvents.COI_GENERATED,
    function (data) {
      if (data?.pdf_url) {
        setCoiPdfUrl(data?.pdf_url);
      }
    }
  );

  let domainOfSuccessUrl;

  if (successUrl) {
    domainOfSuccessUrl = new URL(successUrl).hostname;

    setTimeout(() => {
      document.location.href = successUrl;
    }, 10000);
  }

  return (
    <article className="vh-100 dt-ns w-100">
      <div className="dtc-ns v-mid tc white ph4-l overflow-x-hidden">
        <WinnersSvg />
        <h1 className="f1-ns lh-title near-black pa2 pa0-l">
          You've successfully signed up for coverage!
        </h1>
        <div className="center tc mw7">
          <p className="f3-ns lh-copy near-black measure">
            Check your email for a signed copy of your disclosure and a welcome
            email with details including your coverage start and end period.
          </p>
        </div>

        <div className="center tc mw7">
          {successUrl ? (
            <p className="f5-ns lh-copy mid-gray">
              Redirecting back to{" "}
              {domainOfSuccessUrl ? domainOfSuccessUrl : successUrl}...
            </p>
          ) : coiPdfUrl ? (
            <DownloadButton url={coiPdfUrl} />
          ) : (
            <LoadingButton />
          )}
        </div>
      </div>
    </article>
  );
}

export default ApplicationComplete;
