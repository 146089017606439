export const environments = {
  SANDBOX: "sandbox",
  PRODUCTION: "production",
};

export const paymentTypes = {
  CHECKOUT: "checkout",
  STRIPE: "stripe",
};

export const IASessionSteps = {
  VERIFY_INFO: {
    name: "VERIFY_INFO",
    order: 1,
  },
  APPLICATION_QUESTIONS: {
    name: "APPLICATION_QUESTIONS",
    order: 2,
  },
  ESIGNATURE_DOCUMENT: {
    name: "ESIGNATURE_DOCUMENT",
    order: 3,
  },
  ADD_CARD_DETAILS: {
    name: "ADD_CARD_DETAILS",
    order: 4,
  },
  FINAL_REVIEW: {
    name: "FINAL_REVIEW",
    order: 5,
  },
  APPLICATION_COMPLETE: {
    name: "APPLICATION_COMPLETE",
    order: 6,
  },
};

export const gqlErrors = {
  PAYMENT_NOT_AUTHORIZED: "PAYMENT_NOT_AUTHORIZED",
};

export const pusherEvents = {
  COI_GENERATED: "coi-generated",
};
