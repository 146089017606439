import { Fragment, useState } from "react";
import styled from "styled-components";

import AnimatedButton from "../../components/AnimatedButton";
import RadioButton from "../../components/RadioButton";

import { Title } from "../../styled";

const Container = styled.div.attrs({
  className: "pa3 pa4-m pv5-l ph6-l",
})`
  @media screen and (min-width: 70em) {
    background-image: url("/img/checklist.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: 410px 85px;
  }
`;

const Label = styled.label.attrs({
  className: "f6 fw5 db mb3 mt3 lh-copy",
})``;

export function Headline() {
  return (
    <Container>
      <Title>
        Let’s check your <br /> eligibility
      </Title>
    </Container>
  );
}

export function ApplicationForm(props) {
  const [isChanged, setChanged] = useState(false);
  const {
    stepForward,
    stepBack,
    stepForwardLoading,
    formField: { eligibilityQuestion },
  } = props;

  return (
    <Fragment>
      <div>
        <Label htmlFor={eligibilityQuestion.name}>
          {eligibilityQuestion.label}
        </Label>
        <RadioButton
          onClick={() => setChanged(true)}
          name={eligibilityQuestion.name}
          value={"Yes"}
        />
        <RadioButton
          onClick={() => setChanged(true)}
          name={eligibilityQuestion.name}
          value={"No"}
        />
      </div>
      <div className="mt3 fr">
        <AnimatedButton onClick={stepBack} customStyle={"mr2"}>
          Back
        </AnimatedButton>
        <AnimatedButton
          onClick={stepForward}
          isSubmitting={stepForwardLoading}
          isDisabled={!isChanged}
          primary
        >
          Continue
        </AnimatedButton>
      </div>
    </Fragment>
  );
}
