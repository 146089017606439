import { gql, useQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";

import { useParams } from "react-router-dom";

import { Error } from "../../components/Error/index";

import Wizard from "./Wizard";

const SESSION_QUERY = gql`
  query InsuranceApplicationSession($iaSession: String) {
    insuranceApplicationSession(iaSession: $iaSession) {
      expired
      isEligible
      isEsignatureComplete
      embeddedSigningUrl
      esignDocumentHash
      currentStep
      successUrl
      cancelUrl
      contractor {
        unredactedDict
        firstName
        lastName
        hasBusinessEntity
        address {
          line1
          line2
          line3
          locality
          region
          postalcode
        }
        paymentClientToken
        paymentMethod
      }
      quotes {
        edges {
          node {
            id
            effectiveDate
            netRate
            isGlAndWc
            isGlOnly
            isWcOnly
            premiumAmountOwed
            job {
              name
              entity {
                name
              }
              wage
            }
            tenant {
              organization {
                publicId
                name
              }
            }
          }
        }
      }
      policies {
        edges {
          node {
            id
            pdfUrl
          }
        }
      }
    }
  }
`;

const ActiveApplication = (props) => {
  const { iaSession } = props;
  const { loading, data } = useQuery(SESSION_QUERY, {
    variables: { iaSession },
  });

  if (loading) {
    return (
      <div className="vh-100 dt w-100">
        <div className="dtc v-mid tc white ph3 ph4-l">
          <ReactLoading type={"spin"} color={"#cccccc"} className="center" />
        </div>
      </div>
    );
  }

  if (data) {
    if (data?.insuranceApplicationSession?.expired) {
      alert(
        "Application expired. Please request a new insurance application link."
      );
    } else {
      return <Wizard data={data} {...props} iaSession={iaSession} />;
    }
  }

  return <Error />;
};

function InsuranceApplication(props) {
  const { iaSession } = useParams();
  return <ActiveApplication {...props} iaSession={iaSession} />;
}

export default InsuranceApplication;
