function isInt(n) {
  return n % 1 === 0;
}

export const centsToDollars = (cents) => {
  const options = { style: "currency", currency: "USD" };

  let dollars = cents / 100;
  if (isInt(dollars)) {
    return dollars.toLocaleString("en-US", options).slice(0, -3);
  } else {
    return dollars.toLocaleString("en-US", options);
  }
};
