import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import loadEversign from "./loadEversign";
import AnimatedButton from "../../components/AnimatedButton";
import { Title, IframeWrapperDiv } from "../../styled";

const Container = styled.div.attrs({
  className: "pa3 ph4-m pv3-m pv5-l ph6-l",
})`
  @media screen and (min-width: 70em) {
    background-image: url("/img/london.svg");
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 40% 40%;
    background-position: 400px 120px;
  }
`;

const Esignature = styled.div.attrs({
  className: "w-100 overflow-hidden absolute ba b--black-80",
})`
  height: 88%;
  width: 100%;
`;

export function Headline() {
  return (
    <Container>
      <Title>
        Review your
        <br /> quote
      </Title>
    </Container>
  );
}

export function ReviewQuoteForm(props) {
  const { stepForward, esignEmbedUrl, isEsignatureComplete } = props;

  const [isComplete, setComplete] = useState(isEsignatureComplete);
  const [loaded, setLoaded] = useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    loadEversign(() => {
      setLoaded(true);
    });

    return function cleanup() {
      setLoaded(false);
    };
  }, [loaded]);

  if (esignEmbedUrl && loaded && window.eversign && window.eversign.open) {
    window.eversign.open({
      url: esignEmbedUrl,
      containerID: "eversign",
      width: "100%",
      height: "100%",
      events: {
        loaded: function () {
          console.log("loaded Callback");
        },
        signed: function () {
          setComplete(true);
          stepForward();
          handle.exit();
        },
        declined: function () {
          console.log("declined Callback");
          handle.exit();
        },
        error: function () {
          console.log("error Callback");
          handle.exit();
        },
      },
    });
  }

  return (
    <Fragment>
      <IframeWrapperDiv>
        <div className="flex-auto">
          <div>
            <FullScreen handle={handle}>
              <Esignature id="eversign"></Esignature>
            </FullScreen>
          </div>
          <div className="fixed bottom-0 bottom--1-ns right-0">
            <button
              className="f6 link br2 ph3 pv2 mb2 dib pointer animate mr2 black bg-white b--black ba"
              onClick={handle.enter}
            >
              Enter fullscreen
            </button>
            <AnimatedButton
              id="review-quote-continue"
              isDisabled={!isComplete}
              onClick={stepForward}
              primary
            >
              Continue
            </AnimatedButton>
          </div>
        </div>
      </IframeWrapperDiv>
    </Fragment>
  );
}
