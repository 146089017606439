import { ReactComponent as CPUSvg } from "./cpu.svg";

const Error = () => (
  <article className="vh-100 dt w-100">
    <div className="dtc v-mid tc white ph3 ph4-l">
      <h1 className="f1 lh-title near-black">Error – Invalid URL</h1>
      <CPUSvg />
      <div className="center tc mw7">
        <p className="f3 lh-copy near-black measure">
          Double check the URL and confirm that you have an active insurance
          application on file with 1099Policy.
        </p>
      </div>
    </div>
  </article>
);

export default Error;
